import UniversalLink from 'components/UniversalLink'
import { useLayoutProps } from 'contexts/LayoutContext'
import { ReactComponent as ArrowSVG } from 'images/global/anon-arrow.svg'
import { fmobile, fresponsive, ftablet } from 'library/fullyResponsive'
import useMedia from 'library/useMedia'
import styled, { css } from 'styled-components'
import colors from 'styles/colors'
import textStyles from 'styles/text'

export default function Banner() {
  const desktop = useMedia(true, true, true, false)
  const { banner } = useLayoutProps()
  const textDesktop = banner?.desktopText.desktopText
  const textMobile = banner?.mobileText.mobileText
  const isBannerImportant = banner?.important
  const url = banner?.url ?? ''

  return (
    <Wrapper isBannerImportant={!!isBannerImportant}>
      <Link to={url}>
        <span>{desktop ? textDesktop : textMobile}</span>
        <Arrow />
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isBannerImportant: boolean }>`
  background: ${({ isBannerImportant }) =>
    isBannerImportant ? colors.primaryYellow : colors.dark700};
  color: ${({ isBannerImportant }) =>
    isBannerImportant ? colors.primaryBlack : colors.primaryWhite};
  position: relative;
  z-index: 1;
  border-radius: 100px;

  svg {
    background: ${({ isBannerImportant }) =>
      isBannerImportant ? colors.primaryBlack : colors.primaryYellow};

    path {
      fill: ${({ isBannerImportant }) =>
        isBannerImportant ? colors.primaryYellow : colors.dark700};
    }
  }
`

const Link = styled(UniversalLink)`
  ${textStyles.title4}
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  :hover {
    svg {
      transform: rotate(45deg);
    }
  }

  ${fresponsive(css`
    margin-top: 8px;
    height: 35px;
  `)}
  ${fmobile(css`
    margin-top: 4px;
    ${textStyles.title5};
  `)}
`

const Arrow = styled(ArrowSVG)`
  height: auto;
  transition: transform 0.3s ease-in-out;
  border-radius: 100%;

  ${fresponsive(css`
    width: 16px;
  `)}
  ${ftablet(css`
    width: 12px;
  `)};
  ${fmobile(css`
    margin-top: -2px;
  `)}
`
