import { Link } from 'gatsby'
import { Transitions } from 'library/Loader'
import { loadPage } from 'library/Loader/TransitionUtils'
import libraryConfig from 'libraryConfig'
import { MouseEventHandler } from 'react'

interface BaseLinkProps {
  /**
   * should the link open in a new tab?
   */
  openInNewTab?: boolean
  children: React.ReactNode
  className?: string
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  ariaLabel?: string
  /**
   * what should happen when the button is clicked?
   */
  onClick?: MouseEventHandler
}

interface ButtonProps extends BaseLinkProps {
  /**
   * what type of button is this?
   */
  type: 'submit' | 'button' | 'reset'
  /**
   * forward a ref to the button
   */
  forwardRef?: React.Ref<HTMLButtonElement>
  to?: never
  transition?: never
}

interface AnchorProps extends BaseLinkProps {
  /**
   * where should the link navigate to?
   */
  to: string
  /**
   * which transition should be used when navigating to this link?
   */
  transition?: Transitions
  /**
   * forward a ref to the link or anchor tag
   */
  forwardRef?: React.Ref<HTMLAnchorElement & Link<unknown>>
  type?: never
}

export type UniversalLinkProps = ButtonProps | AnchorProps

const trackLink = (destination: string) => {
  window.analytics?.track?.('link_clicked', {
    destination,
    utm_source: window.utmSource,
    utm_medium: window.utmMedium,
    utm_campaign: window.utmCampaign,
  })
}

/**
 * a link that navigates when clicked, using the specified transition
 * @returns
 */
export default function UniversalLink({
  to,
  transition = libraryConfig.defaultTransition,
  openInNewTab = false,
  forwardRef,
  type,
  children,
  ariaLabel,
  onClick,
  ...props
}: UniversalLinkProps) {
  if (type) {
    return (
      <button
        type={type}
        ref={forwardRef}
        {...props}
        onClick={onClick}
        style={{
          cursor: 'pointer',
        }}
      >
        {children}
      </button>
    )
  }

  const internal = /^\/(?!\/)/.test(to)

  const handleClick: React.MouseEventHandler = (e) => {
    e.preventDefault()
    trackLink(to)

    if (onClick) {
      onClick(e)
    }

    if (openInNewTab || !internal) {
      window.open(to, '_blank')
    } else {
      loadPage(to, transition).catch((error: string) => {
        throw new Error(error)
      })
    }
  }

  return internal ? (
    <Link to={to} onClick={handleClick} ref={forwardRef} aria-label={ariaLabel} {...props}>
      {children}
    </Link>
  ) : (
    <a href={to} onClick={handleClick} ref={forwardRef} aria-label={ariaLabel} {...props}>
      {children}
    </a>
  )
}
