import { useLayoutContext } from 'contexts/LayoutContext'
import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ReactComponent as LogoSVG } from 'images/global/RunwayLogo.svg'
import { fmobile, fresponsive, ftablet } from 'library/fullyResponsive'
import getMedia from 'library/getMedia'
import { registerLoaderCallback, unregisterLoaderCallback } from 'library/Loader/LoaderUtils'
import { getResponsivePixels, getVH } from 'library/viewportUtils'
import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import colors from 'styles/colors'
import { eases } from 'styles/eases'

gsap.registerPlugin(CustomEase as object)

const rotation = -5

export default function Preloader() {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const logoRef = useRef<HTMLDivElement>(null)
  const maskRef = useRef<HTMLDivElement>(null)
  const loadComplete = useRef(false)

  const { layoutProps } = useLayoutContext()
  const { hasBanner } = layoutProps

  const onComplete = () => {
    loadComplete.current = true
    ScrollSmoother.get()?.paused(true)
  }

  useEffect(() => {
    ScrollSmoother.get()?.scrollTop(0)
    ScrollSmoother.get()?.paused(true)

    const animateOut = () => {
      const outTl = gsap.timeline({
        defaults: {
          ease: eases.goopyLoopy,
        },
        onStart: () => {
          ScrollSmoother.get()?.scrollTop(0)
          ScrollSmoother.get()?.paused(true)
        },
        onComplete: () => {
          ScrollSmoother.get()?.paused(false)
          ScrollTrigger.refresh()
          gsap.set(wrapperRef.current, {
            display: 'none',
          })
        },
      })

      outTl.to(
        maskRef.current,
        {
          width: getResponsivePixels(100),
          height: getResponsivePixels(27),
          top: () =>
            getMedia(
              getVH(100) + getResponsivePixels(hasBanner ? 70 : 26.45),
              getVH(100) + getResponsivePixels(hasBanner ? 70 : 26.45),
              getVH(100) + getResponsivePixels(hasBanner ? 82 : 39),
              getVH(100) + getResponsivePixels(hasBanner ? 71.5 : 36),
            ),
          duration: 0.75,
          y: 0,
          yPercent: -50,
        },
        0,
      )

      outTl.to(
        maskRef.current,
        {
          left: () =>
            getMedia(
              getResponsivePixels(15),
              getResponsivePixels(15),
              getResponsivePixels(20),
              getResponsivePixels(23),
            ),
          x: 0,
          xPercent: 0,
          duration: 0.75,
        },
        0.75,
      )

      outTl.to(
        wrapperRef.current,
        {
          y: '-100%',
          duration: 0.75,
        },
        0,
      )

      outTl.fromTo(
        'main',
        {
          y: getVH(100),
        },
        {
          duration: 0.75,
          y: 0,
        },
        0,
      )

      outTl.fromTo(
        ['.header-logo', '.nav-link-mobile'],
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        1.5,
      )

      outTl.set(
        ['.nav-link', '#open-access-desktop'],
        {
          y: '-250%',
        },
        0,
      )

      outTl.to(
        ['.nav-link', '#open-access-desktop'],
        {
          y: 0,
          stagger: {
            each: 0.05,
            from: 'end',
          },
          duration: 0.2,
          ease: 'power2.out',
        },
        0.75,
      )

      outTl.set(
        'main',
        {
          clearProps: 'all',
        },
        0.76,
      )

      outTl.to(
        logoRef.current,
        {
          opacity: 0,
          duration: 0.4,
        },
        '-=0.4',
      )
    }

    const loop = () => {
      const loopTl = gsap.timeline({
        defaults: {
          ease: eases.goopyLoopy,
          duration: 0.75,
        },
      })

      loopTl.fromTo(
        logoRef.current,
        {
          y: 0,
          rotate: 0,
        },
        {
          y: '-115%',
          rotate: rotation,
        },
        0,
      )

      loopTl.fromTo(
        logoRef.current,
        {
          y: '115%',
          rotate: rotation,
        },
        {
          y: 0,
          rotate: 0,
        },
        0.75,
      )
    }

    const tl = gsap.timeline({
      defaults: {
        ease: eases.goopyLoopy,
        duration: 0.75,
      },
      onComplete: () => {
        if (loadComplete.current) {
          animateOut()
        } else {
          let time = 0
          const firstInterval = setInterval(() => {
            time += 100
            if (loadComplete.current) {
              clearInterval(firstInterval)
              animateOut()
            } else if (time >= 3000) {
              clearInterval(firstInterval)
              loop()
              const interval = setInterval(() => {
                if (loadComplete.current) {
                  clearInterval(interval)
                  animateOut()
                } else {
                  loop()
                }
              }, 1500)
            }
          }, 100)
        }
      },
    })

    tl.fromTo(
      logoRef.current,
      {
        y: '115%',
        rotate: rotation,
      },
      {
        y: 0,
        rotate: 0,
      },
      0,
    )

    registerLoaderCallback({
      callback: onComplete,
      duration: 1.5,
    })

    return () => {
      unregisterLoaderCallback(onComplete)
    }
  }, [hasBanner])

  return (
    <Wrapper ref={wrapperRef}>
      <Mask ref={maskRef}>
        <LogoWrapper ref={logoRef}>
          <Logo />
        </LogoWrapper>
      </Mask>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  background-color: ${colors.primaryBlack};
  z-index: 100;
  pointer-events: none;
  height: 100vh;
  width: 100vw;
`

const Mask = styled.div`
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${fresponsive(css`
    width: 1084px;
    height: 295px;
  `)}

  ${ftablet(css`
    width: 900px;
  `)}
  
  ${fmobile(css`
    width: 300px;
    height: 100px;
  `)}
`

const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateY(115%) rotate(${rotation}deg);
`

const Logo = styled(LogoSVG)`
  height: auto;
  width: 100%;
`
